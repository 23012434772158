import { Container, Flex } from 'theme-ui';

const OffsetContainer = ({ children, sidebar, sx }) => {
  return (
    <Container
      variant="offsetContainer"
      sx={{
        marginLeft: sidebar ? ['0', '0', '0', '0', 'auto'] : 'auto',
        marginRight: sidebar ? ['0', '0', '0', '0', 'auto'] : 'auto',
        marginTop: ['24px', '24px', '24px', '48px', '48px'],
        marginBottom: ['48px', '48px', '48px', '96px', '96px'],
        ...sx,
      }}
    >
      <Flex
        sx={{
          flexFlow: [
            'column nowrap',
            'column nowrap',
            'column nowrap',
            'row nowrap',
            'row nowrap',
          ],
          gridGap: '18px',
          position: 'relative',
          margin: ['0', '0', '0', '0', '0 auto'],
          justifyContent: sidebar ? 'space-between' : 'center',
          left: sidebar ? ['0', '0', '0', '0', '-119px'] : '0',
        }}
      >
        {sidebar && sidebar}
        {children}
      </Flex>
    </Container>
  );
};

export default OffsetContainer;
